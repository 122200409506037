<template>
  <b-card title="Today's Bills">
    <bill-filters v-model="filters" />
    <bills-list :filters="parsedFilter" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import dateMixin from '@/mixins/dateMixin'
import BillsList from '../../components/PartyLedger/BillsList.vue'
import BillFilters from '../../components/TodayBill/BillFilters.vue'

export default {
  components: {
    BCard,
    BillsList,
    BillFilters,
  },
  mixins: [dateMixin],
  data() {
    return {
      filters: {
        bill_no: null,
        date: `${this.formatedDate(new Date(), 'YYYY-MM-DD')} to ${this.formatedDate(new Date(), 'YYYY-MM-DD')}`,
        party: null,
        isTodayList: true,
      },
    }
  },
  computed: {
    parsedFilter() {
      const { date } = this.filters
      const [fromDate, toDate] = date ? date.split(' to ') : []
      let parsedDate
      if (fromDate && toDate) {
        parsedDate = {
          from_date: fromDate,
          to_date: toDate,
        }
      } else {
        parsedDate = {
          from_date: null,
          to_date: null,
        }
      }
      const filter = {
        ...this.filters,
        ...parsedDate,
      }
      delete filter.date
      return filter
    },
  },
}
</script>
