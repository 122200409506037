<template>
  <div>
    <b-row>
      <b-col lg="2">
        <b-form-group
          label="Bill Number"
        >
          <b-form-input
            v-model="billNo"
            placeholder="Enter unique bill number"
          />
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group
          label="Date Range"
        >
          <FlatPicker
            v-model="filters.date"
            :config="{ mode: 'range'}"
          />
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group
          label="Party Selection"
        >
          <custom-v-select
            v-model="filters.party"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="buyerOptions"
            label="text"
            placeholder="Select buyer name"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :loading="loading"
            multiple
            :reduce="(option => option.id)"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'
import FlatPicker from '../UI/FlatPicker.vue'

export default {
  components: {
    FlatPicker,
    BRow,
    BCol,
    BFormGroup,
    CustomVSelect,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      buyerOptions: [],
    }
  },
  computed: {
    filters: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    billNo: {
      get() {
        return this.filters.bill_no
      },
      set(newVal) {
        this.updateFilters({ bill_no: newVal })
      },
    },
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    async fetchOptions() {
      this.loading = true
      await this.fetchBuyerOptions()
      this.loading = false
    },

    async fetchBuyerOptions() {
      try {
        const { data } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = data.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message || 'Something went wrong!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      }
    },
    updateFilters: debounce(function updateFilters(newFilter) {
      this.$emit('input', {
        ...this.filters,
        ...newFilter,
      })
    }, 500),
  },

}
</script>
